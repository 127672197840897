.warrningModal {
    user-select: none;
    z-index: 9999999;
    position: fixed;
    background-color: #fff5eb;
    border-radius: 30px;
    padding: 5px;
    width: 500px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 250px);

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        border: 1px solid #de8f40;
        border-radius: 25px;
    }

    &-img {
        display: flex;
        justify-content: center;
        margin: 15px;
    }

    &-text {
        display: flex;
        justify-content: center;
        margin: 15px;
        color: #de8f40;
        font-weight: 900;
        font-size: 20px;
        word-break: break-word;
        overflow-y: auto;
        min-height: 45px;
        max-height: 100px;
        text-align: center;

        &::-webkit-scrollbar {
            width: 3.33px;
        
            &-thumb {
                background-color: #de8f40;
                outline: 1px solid #de8f40;
                border-radius: 25px;
                box-shadow: 0 0 3.33px 1.11px rgba(127, 170, 176, 0.333);
            }
      
            &-track {
                -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.111);
            }
        }
    }

    &-close {
        position: absolute;
        right: 0;
        margin: 5px 10px;
        height: 25px;
        width: 25px;
        cursor: pointer;

        &-src {
            height: 100%;
            width: 100%;
        }
    }
}

@media screen and ( max-width: 1000px ) {
    .warrningModal {
        max-width: 300px;
        left: calc(50% - 150px) !important;
    }
}

@media screen and ( max-width: 350px ) {
    .warrningModal {
        max-width: 200px;
        left: calc(50% - 100px) !important;
    }
}