.firstScreen {
    &_title {
        margin-top: 209px;
        color: #464e50;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 10px;
        line-height: 1;
        font-weight: 500;
        font-family: 'Oswald', sans-serif !important;
        text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.644);
        font-size: 70px;
        line-height: 1.7;
    }
}

@media screen and ( min-width: 2600px ) {
    .firstScreen {
        margin: 450px auto;
    }
}

@media screen and ( min-width: 2300px ) and ( max-width: 2599px ) {
    .firstScreen {
        margin: 300px auto;
    }
}

@media screen and ( min-width: 2000px ) and ( max-width: 2299px ) {
    .firstScreen {
        margin: 200px auto;
    }
}

@media screen and ( min-width: 1400px ) and ( max-width: 1600px ) {
    .firstScreen_title {
        font-size: 65px;
    }
}

@media screen and ( min-width: 1200px ) and ( max-width: 1399px ) {
    .firstScreen_title {
        font-size: 50px;
    }
}

@media screen and ( min-width: 1120px ) and ( max-width: 1199px ) {
    .firstScreen_title {
        font-size: 60px;
    }
}

@media screen and ( min-width: 1000px ) and ( max-width: 1119px )  {
    .firstScreen {
        margin-bottom: 209px;
    }

    .firstScreen_title {
        margin-top: 0 !important;
        font-size: 55px;
    }

    .who_we_are {
        margin-top: -220px;
    }

    .button_bl {
        display: flex;
        flex-basis: 100%;
        &.grey_link {
            width: 100%;
        }
    }

    .memory_page {
        margin-top: -10px;
        margin-bottom: 40px;
    }

    .memorial_can_be {
        padding-bottom:  0;
    }
}

@media screen and ( min-width: 1000px ) and ( max-width: 1100px ) {
    .firstScreen {
        margin-bottom: 209px;
    }

    .firstScreen_title {
        margin-top: 0 !important;
        font-size: 50px;
    }
}

@media screen and ( max-width: 1119px ) {
    .first_screen {
        padding-bottom: 150px;
    }
}

@media screen and ( max-width: 1000px ) {

    .firstScreen {
        width: 100% !important;
        margin-bottom: 400px;
        margin-left: 30px;
    }

    .firstScreen_title {
        margin-top: 100px !important;
    }

    .who_we_are {
        margin-top: -320px;
    }
}

@media screen and ( min-width: 950px ) and ( max-width: 1000px ) {
    .firstScreen_title {
        font-size: 50px;
    }
}

@media screen and ( min-width: 850px ) and ( max-width: 949px ) {
    .firstScreen_title {
        font-size: 45px;
    }
}

@media screen and ( min-width: 800px ) and ( max-width: 849px ) {
    .firstScreen_title {
        font-size: 43px;
    }
}

@media screen and ( min-width: 664px ) and ( max-width: 799px ) {
    .firstScreen_title {
        font-size: 40px;
    }
}

@media screen and ( min-width: 565px ) and ( max-width: 663px ) {
    .firstScreen_title {
        font-size: 35px;
    }
}

@media screen and ( min-width: 461px ) and ( max-width: 564px ) {
    .firstScreen_title {
        font-size: 30px;
    }
}

@media screen and ( min-width: 381px ) and ( max-width: 460px ) {
    .firstScreen_title {
        font-size: 25px;
    }
}

@media screen and ( max-width: 380px ) {
    .firstScreen {
        margin-left: 5px !important;
    }

    .firstScreen_title {
        font-size: 20px;
    }
}