@import "../../../styles/theme.scss";

.newFooterMainContainer {
  display: flex;
  flex-direction: column;
  margin: 2%;

  .logoAndSocial {

    @include tablet {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    background-color: $deep-green;
    color: $white-color;
    border-radius: 30px;
    padding: 50px 5%;

    @include tablet {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }

    @include mobile {}
  }
}