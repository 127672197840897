@import "../../../styles/theme.scss";

.inputWrapper {
  position: relative;
  font-size: 16px;

  @media (max-width: 500px) {
    margin-right: 0;
    width: 100%;
  }

  .divWrapper {
    position: relative;
    display: flex;
    align-items: center;
    @media (max-width: 500px) {
      width: 100%;
    }

    .arrowUp {
      border-bottom: 4px solid black;
      // position: absolute;
      // right: 18px;
    }

    .arrowDown {
      border-top: 4px solid black;
      // position: absolute;
      // right: 18px;
    }

    div {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }

  input {
    position: relative;
    border: none;
    border-radius: 6px;
    color: black;
    font-size: 16px;
    padding: 12px 4px 12px 12px;
    caret-color: transparent;

    @media (max-width: 500px) {
      width: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.options {
  position: absolute;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.11);
  z-index: 5;

  .optionLink {
    list-style-type: none;
    &:hover,
    &:focus {
      cursor: pointer;
    }

    p {
      @extend %p1;
      margin: 6px 12px;
    }
  }
}
