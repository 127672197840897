@import "../../../styles/theme.scss";

.newHeaderContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 31px 5%;
  align-items: center;
  background-color: #fff;

  @include tablet {
    height: 88px;
  }

  @include small-laptop {
    padding: 14px 20px;
  }

  .navlinksContainer {
    display: flex;
    margin: 0 auto;
    gap: 60px;

    @media (max-width: 915px) {
      display: none;
    }
  }

  .buttonText,
  .selectContainer {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .buttonText {
    @include small-laptop {
      img {
        transform: scale(0.8);
      }
      span {
        display: none;
      }
    }
  }

  .selectContainer {
    gap: 0;
    margin-left: auto;
    margin-right: 40px;
    position: absolute;
    right: 8%;

    @media (max-width: 1121px) {
      display: none;
    }

    @media (min-width: 1200px) {
      position: initial;
    }
  }

  .buttonAndBurger {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-24px);
    right: 5%;
    z-index: 999;

    @media (min-width: 1200px) {
      position: initial;
      transform: translateY(0);
    }
  }

  .burgerIcon {
    display: none;
    cursor: pointer;

    @media (max-width: 1121px) {
      display: block;
      margin-left: 12px;
      top: 0%;
      right: 1%;
    }

    @media (max-width: 700px) {
      display: block;
      position: initial;
      margin-left: 12px;
    }
  }

  .burgerIconAuth {
    display: none;
    cursor: pointer;

    @media (max-width: 915px) {
      display: block;
      position: relative;
      top: 1%;
      right: 0%;
      margin-left: 12px;
    }
  }

  .burgerIconNoAuth {
    @media (max-width: 915px) {
      position: relative;
    }
  }

  .mainBlock {
    display: flex;
    justify-content: space-between;
  }

  .mobileMenuWrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $black-color;
    z-index: 99;
    opacity: 0.8;
  }

  .mobileMenu {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    left: 25%;
    height: 100%;
    background-color: #fff;
    padding: 26px;

    .crossWrapper {
      position: absolute;
      top: 26px;
      right: 26px;
      z-index: 999;
    }

    .mobileSelectContainer {
      display: flex;
      margin-bottom: 42px;
      width: fit-content;

      img {
        object-fit: contain;
      }
    }

    .mobileNavlinksContainer {
      display: flex;
      flex-direction: column;
      padding-top: 50px;

      a {
        position: relative;
        z-index: 999;
        padding-bottom: 20px;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 20px;
      }
    }
  }
}
