.logoutModal {
    user-select: none;
    z-index: 9999999;
    height: 300px;
    width: 500px;
    top: calc(50% - 150px);
    left: calc(50% - 250px);
    position: fixed;
    background-color: #fff9f9;
    border-radius: 30px;
    padding: 5px;

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        border: 1px solid #6a898c;
        border-radius: 25px;
    }

    &-img {
        display: flex;
        justify-content: center;
        margin: 15px;
    }

    &-text {
        display: flex;
        justify-content: center;
        margin: 15px;
        color: #6a898c;
        font-weight: 900;
        font-size: 20px;
        word-break: break-word;
    }

    &-close {
        position: absolute;
        right: 0;
        cursor: pointer;
    }

    &-navigation {
        display: flex;
        justify-content: center;

        &-btn {
            margin: 5px 15px;
            padding: 15px 30px;
            color: white;
            font-weight: 700;
            border: none;
            border-radius: 5px;
            outline: none;
            cursor: pointer;

            &:hover {
                opacity: 0.777;
            }
        }
    }
}

.logoutLogout {
    background-color: #464e50;
}

.logoutClose {
    background-color: #6a898c;
}
