@import "../../../../../styles/theme.scss";

.footerLinksMainContainer {
  display: flex;
  justify-content: space-between;
  width: fit-content;

  @include mobile {
    flex-direction: column;
  }

  a {
    min-width: max-content;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding-left: 20px;
    border-left: 1px solid #17393e;
    margin-right: 140px;

    @include tablet {
      margin: 0;
      border: none;

      &:nth-child(1) {
        margin-right: 230px;
      }
    }

    @include mobile {
      border-left: none;
      margin: 0;
      padding: 0;
      padding-top: 20px;
      margin-top: 30px;
      text-align: center;
      border-top: 1px solid #17393e;

      &:nth-child(1) {
        margin-right: 0;
      }
    }

    a {
      font-size: 16px;
    }
  }
}