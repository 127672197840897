/*-------------------------header------------------------*/
.logo {
    font-weight: 700;
    font-size: 23px; 
  }
  
.header {
display: flex;
flex-direction: column;
padding: calc(100% / 90);
}

.header_navig {
    justify-content: flex-end;
    flex-basis: 25%;
}

.header_navig span {
    color: white;
}

.header_navig a span {
    display: block;
    font-weight: 500;
    margin-top: 10px;
}

.header_row {
width: 100%;
max-width: 1230px;
margin-left: auto;
margin-right: auto;
align-items: center; }

.header_logo {
flex-basis: 35%;
display: flex;
align-items: center;
}

.header_right_open {
align-items: center; 
}

.header_menu {
flex-basis: 75%;
}

.hamburger {
    display: block;
    width: 50px;
    margin-left: auto;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer; }
  .hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #464e50;
    border-radius: 9px;
    opacity: 1;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
  .hamburger span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    width: 80%; }
  .hamburger span:nth-child(2) {
    top: 11px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; }
  .hamburger span:nth-child(3) {
    top: 22px;
    width: 80%;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; }
  .hamburger.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -4px;
    left: 16px; }
  .hamburger.open span:nth-child(2) {
    width: 0%;
    opacity: 0; }
  .hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 25px;
    left: 16px; 
}

.open_menu {
    justify-content: flex-end; 
}

.profilemenu .open_menu li a {
    padding: 10px 0 !important;
  }

.open_menu li a {
font-weight: 700;
padding: 5px 20px;
letter-spacing: 1px;
border: 2px solid transparent;
display: grid;
grid-template-columns: 1fr; 
}

.open_menu li.active a {
    // background-color: #464e50;
    color: #fefefe;
    // border-color: #464646;
}

.header_right {
    flex-basis: 100%;
    align-items: center; 
}

@media screen and ( max-width: 1119px ) {
    .profilemenu:hover .profilemenu-dropdown {
        display: flex;
    }

    .profilemenu-dropdown {
        display: block;
    }
}

.profilemenu:hover .profilemenu-dropdown {
    visibility: visible;
    transform: translateY(5px);
    transition: 0.555s;

    &::before {
        visibility: visible;
    }
}

.profilemenu-dropdown {
    visibility: hidden;
    transform: translateY(-5px);
    transition: all 0.1s ease-in-out;

    &::before {
        visibility: hidden;
    }
}

@media screen and ( min-width: 1120px ) {
    // .profilemenu:hover .profilemenu-dropdown {
    //     visibility: visible;
    //     transform: translateY(5px);
    //     transition: 0.555s;

    //     &::before {
    //         visibility: visible;
    //     }
    // }

    // .profilemenu-dropdown {
    //     visibility: hidden;
    //     transform: translateY(-5px);
    //     transition: all 0.1s ease-in-out;

    //     &::before {
    //         visibility: hidden;
    //     }
    // }
}

.headeravatar {
    position: relative;
    list-style-type: none;
}

.pmenu {
    display: flex;
    justify-content: center;
}

.profilemenu {
    &-dropdown {
        z-index: 1000000000000000000;
        position: absolute;
        right: calc(50% - 35px);
        width: 220px;
        padding: 5px 18px;
        background: #FFFFFF;
        box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        list-style-type: none;

        &::before {
            content: '';
            position: absolute;
            top: -7px;
            left: calc(50% - 10px);
            border: 10px solid transparent;
            top: -20px;
            // border-bottom: 10px solid rgba(133, 180, 186, 0.7);
            margin: auto 0;
        }
    }

    &-list-item {
        &-text {
            transition: none;

            &-b {
                transition: none;
            }
        }

        &:hover {
            background-color: #E4F5F6;
            border-radius: 10px;
        }
    }

    &-headerAvatar {
        cursor: pointer;
        box-shadow: 0 0 3.33px 1.11px rgba(0,0,0,0.111);
        border: 1px solid #296B73;
        width: 50px;
        height: 50px;
    }
}

.profilemenu-list {
    flex-direction: column;
    justify-content: center;

    &-item {
        display: block;
        &-text {
            display: flex;
            justify-content: center;
            width: 100%;

            &-b {
                position: relative;
                font-family: 'Gilroy';
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: #333333;

                &-notificationsCounter {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    top: calc(50% - 7.5px);
                    left: -20%;
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    background-color: red;
                }
            }
            &-b:hover {
                background: #E4F5F6;
                border-radius: 10px;
            }
        }
    }
    
}

.headerdflex {
    flex-wrap: nowrap !important;
}

.headermenutext {
    width: 100%;
    margin: 0 5px;
}

.headerMenu-link {
    display: flex;
    justify-content: center;
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notificationBtn {
    position: relative;
    height: 50px;
    width: 50px;
    color: #464e50 !important;

    &-notifysCounter {
        position: absolute;
        display: flex;
        justify-content: center;
        text-align: center;
        top: calc(0% + 10px);
        right: calc(0% + 20px);
        height: 13px;
        width: 13px;
        background-color: red;
        border-radius: 50%;
        font-size: 9px;
        color: black;
        font-weight: 700;
    }

    &-container {
        transform: translateY(-5px);
        z-index: 999999;
        user-select: none;
        display: flex;
        position: absolute;
        max-height: 450px;
        width: 500px;
        border-radius: 10px;
        right: calc(0% - 250px + 25px);
        background-color: rgba(133, 180, 186, 0.777);
        font-weight: 500;
        color: #464e50;
    }

    &-dropdownmenu {
        width: 100%;
        padding: 5px;
        overflow: auto;

        &-item {
            display: flex;
            flex-direction: column;
            min-height: 75px;
            width: 100%;
            margin-bottom: 5px;
            border-radius: 5px;
            background-color: white;
            border: 0.25px solid #6a8c91;
            opacity: 0.777;

            &:last-of-type {
                margin-bottom: 0;
            }

            &-top {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 15px;
                height: 25px;
                color: #6a8c91;
                font-weight: 700;
                width: 100%;
            }

            &-mid {
                display: flex;
                padding: 15px;
                text-align: left;
                word-wrap: break-word;
                overflow: hidden;
                max-height: 50%;
                width: 100%;
                font-weight: 500;
            }

            &-bottom {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0 15px;
                height: 25px;
                width: 100%;
                color: #6a8c91;
                font-weight: 700;
            }

            &-navigation {
                display: flex;
                width: 100%;
                padding: 0 15px;
                margin: 0 0 15px 0;
                justify-content: space-between;

                &-button {
                    display: flex;
                    justify-content: center;
                    height: 100%;
                    width: 45%;
                    border-radius: 5px;
                    color: white;
                    font-weight: 500;
                    line-height: 2;
                    text-transform: uppercase;
                    border: none;
                    outline: none;
                    cursor: pointer;
                }
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    &-noItem {
        display: flex;
        justify-content: center;
        font-weight: 500;
        color: #464e50;
        cursor: pointer;
    }
}

.readed {
    background-color: rgba(74, 102, 107, 0.444);
}

.notifyConfirm {
    background-color: #6a8c91;
    
    &:hover {
        background-color: rgba(106, 140, 145, 0.777);
    }
}

.notifyDecline {
    background-color: #b6b5b5;

    &:hover {
        background-color: rgba(182, 181, 181, 0.777);
    }
}

.notificationItem {
    text-transform: none;
}

.notifyWithLink {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

@media screen and ( min-width: 3000px ) {
    .profilemenu-dropdown {
        right: 0 !important;
        padding: 10px !important;
      }
}

@media screen 
    and ( min-width: 1200px )
    and ( max-width: 1400px ) {
    .open_menu li a {
        font-size: 8px !important;
    }
}

@media screen and (min-width: 1800px) and (max-width: 1900px) {
    .open_menu li a {
        padding: 5px 20px !important;
    }
    }

    @media screen and (min-width: 1700px) and (max-width: 1800px) {
    .open_menu li a {
        padding: 5px 10px !important;
    }
    }

    @media screen and (min-width: 1600px) and (max-width: 1700px) {
    .open_menu li a {
        padding: 5px 5px !important;
    }

    .first_screen_title {
        font-size: 65px;
    }
}

@media screen and ( min-width: 3000px ) {
    .header_row {
        max-width: 2100px !important;
      }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .header_logo {
        flex-basis: 39%; 
    }
}

@media screen and ( max-width: 1399px ) {
    .logo {
        font-size: 20px; 
    }

    .header_fixed .hamburger {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .header_fixed .header_logo {
        padding: 0;
    }
    .header_fixed .header_right_open {
        top: 45px;
    }
    .header_fixed .header_text:before {
        height: 50px;
    }

    .header_navig {
        justify-content: center;
    }

    .header_navig a {
        padding: 10px;
        span {
            margin-top: 3px;
        }
    }

    .header_menu {
        padding-top: 0;
        flex-basis: 100%;
        margin-right: 30px;
    }

    .header_logo {
        flex-basis: 58%;
        padding-top: 0;
        padding: 15px 0;
    }

    .header_fixed .header_logo {
        padding: 0; 
    }

    .header_logo {
        flex-basis: 58%;
        padding-top: 0;
        padding: 15px 0; 
    }

    .with_fixed_header {
        padding-top: 50px; 
    }

    .header_fixed .hamburger {
        margin-top: 10px;
        margin-bottom: 10px; 
      }
    
      .header_fixed .header_text:before {
        height: 50px; 
    }

    .header_navig {
        justify-content: center; 
    }
    
    .header_navig a {
        padding: 10px 25px; 
    }

    .header_navig a span {
        margin-top: 3px; 
    }

    .header_menu {
        padding-top: 0;
        flex-basis: 100%;
        margin-right: 30px;
    }

    .header_text {
        padding-left: 10px;
        margin-left: 10px;
    }

    .open_menu li a {
        padding: 5px 1px !important;
    }

    .open_menu li a {
        display: block;
        text-align: center; 
    }
}

@media screen and ( min-width: 1120px ) {
    .hamburger {
        display: none; 
    }
}

@media screen and ( min-width: 1120px ) and ( max-width: 1199px ) {
    .header_right {
        flex-basis: 95%;
        margin: auto;
    }
}

@media screen and ( min-width: 1200px ) and ( max-width: 1400px ) {
    .header_row {
        width: 70% !important;
        margin: 0 auto !important;
    }
}

@media screen and ( max-width: 1120px ) {
    .header_right_open {
        z-index: 999;
        border: 1px solid #fefefe;
        display: none;
        position: absolute;
        color: #fefefe;
        right: 0;
        top: 50px;
        max-width: 450px;
        width: 70%;
        padding: 30px 20px 20px;
        background-color: rgba(133, 180, 186, 0.8);
        flex-direction: column; 
    }

    .header_menu {
        margin: 0;
    }

    .header_fixed .header_right_open {
        top: 45px; 
    }

    .header_right {
        flex-basis: 42%; 
    }

    .header_logo {
        flex-basis: 100%;
        justify-content: center;
    }

    .open_menu {
        flex-direction: column;
    }

    .profilemenu-dropdown {
        width: 100%;
        left: 0;
    }
}

@media screen and ( min-width: 760px ) and ( max-width: 980px ) {
    .header_logo {
        flex-basis: 68%; 
    }

    .header_right {
        flex-basis: 32%; 
    }
}

@media screen and ( min-width: 460px ) and ( max-width: 759px ) {
    .header_logo {
        flex-basis: 78%; 
    }

    .header_right {
        flex-basis: 22%; 
    }
    
      .header_right_open {
        width: 80%; 
    }
}

@media screen and ( max-width: 460px ) {
    .header_logo {
        flex-basis: 76%; 
    }

    .header_text {
        display: none; 
    }
    
    .header_right {
        flex-basis: 24%; 
    }

    .header_right_open {
        width: 100%; 
    }
}

@media  screen and ( min-width: 1755px ) and ( max-width: 1900px ) {
    .notificationBtn-container {
        width: 400px;
        right: calc(0% - 200px + 50px);
    }
}

@media  screen and ( min-width: 1645px ) and ( max-width: 1755px ) {
    .notificationBtn-container {
        width: 400px;
        right: calc(0% - 200px + 100px);
    }
}

@media  screen and ( min-width: 1500px ) and ( max-width: 1645px ) {
    .notificationBtn-container {
        width: 400px;
        right: calc(0% - 200px + 150px);
    }
}

@media  screen and ( min-width: 1300px ) and ( max-width: 1500px ) {
    .notificationBtn-container {
        width: 400px;
        right: calc(0% - 200px + 250px);
    }
}

@media  screen and ( min-width: 570px ) and ( max-width: 1300px ) {
    .notificationBtn-container {
        width: 450px;
        right: calc(0% - 225px + 32px);
    }

    .header_navig a {
        padding: 10px; 
    }
    
    .header_text {
        padding-left: 10px;
        margin-left: 10px; 
    }
}

@media screen and ( max-width: 572px )  {
    .notificationBtn-container {
        width: 425px;
        right: calc(0% - 212.5px + 32px);
    }
}

.MuiAvatar-root {
    width: 50px !important;
    height: 50px !important;
}