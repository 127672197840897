@import "../../../../../styles/theme.scss";

.footerBottomContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
  margin-left: 130px;
  margin-right: 80px;
  color: $grey-color;
  font-size: 14px;

  @include mobile {
    flex-direction: column;
    margin: 26px auto 21px auto;
    text-align: center;
  }

  .copyright {
  }

  .designBy {
    display: flex;
    gap: 5px;

    @include mobile {
      justify-content: center;
      align-items: center;

      p {
        margin-top: 16px;
      }
    }
  }
}
