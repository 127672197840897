/*-------------------------kabinet------------------------*/

.profile-container {
    padding: 60px 10%;
}

.moder {
    &__item {
        margin-bottom: 90px;
        position: relative;
        border-bottom: 1px solid #e5e5e5;
        &:before {
            content: "";
            display: block;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            background-color: #4faa0d;
            position: absolute;
            bottom: -5px;
            left: 0;
            z-index: 1;
            height: 8px;
            width: 8px;
        }
        &:after {
            content: "";
            display: block;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            background-color: #4faa0d;
            position: absolute;
            bottom: -5px;
            right: 0;
            z-index: 1;
            height: 8px;
            width: 8px;
        }
    }
    &__header {
        padding-right: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
        position: relative;
        ul {
            display: flex;
            font-size: 14px;
            color: #000;
            justify-content: flex-end;
            a {
                display: block;
                margin-left: 35px;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &:before {
            content: "";
            display: block;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            background-color: #4faa0d;
            position: absolute;
            bottom: -5px;
            left: 0;
            z-index: 1;
            height: 8px;
            width: 8px;
        }
        &:after {
            content: "";
            display: block;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            background-color: #4faa0d;
            position: absolute;
            bottom: -5px;
            right: 0;
            z-index: 1;
            height: 8px;
            width: 8px;
        }
    }
    &__public {
        color: #4faa0d;
    }
    &__name {
      margin-right: 20px;
    }
    &__delete {
        color: #dc4409;
    }
    &__content {
        padding-top: 15px;
        font-size: 16px;
        color: #171717;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 1.7;
    }
    &__row {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    &__ritem {
        position: relative;
        margin-left: 15px;
        margin-bottom: 15px;
    }
    &__itemdelete {
        position: absolute;
        right: -7px;
        top: -8px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        &:hover {
            opacity: 0.7;
        }
    }
    &__itemins {
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        overflow: auto;
        max-width: 195px;
        width: 100%;
        max-height: 195px;
    }
    &__itemvideo {
        width: 255px;
        height: 160px;
        iframe {
            width: 255px !important;
            height: 160px !important;
        }
    }
    .show_more_cards {
        width: 50px;
        height: 50px;
        img {
            max-width: 30px;
        }
    }
}

.poster__title {
    text-transform: uppercase;
    font-size: 15px;
    color: #464e50;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.memoimages_title {
    margin-top: 45px;
}

.load_image_btn {
    width: 250px !important;
    margin-bottom: 30px;
}

.kabinetrow__title {
    text-transform: uppercase;
    font-size: 25px;
    color: #464e50;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 30px;
}

.kabinetrow {
    position: relative;
    display: flex;
    justify-content: space-between;

    &__left {
        flex-basis: 400px;
        overflow-y: auto;
        margin-top: 35px;
        padding-right: 10px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px #d1d1d1;
        }
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #7faab0;
            outline: 1px solid #7faab0;
            box-shadow: 0 0 3.33px 1.11px rgba(127, 170, 176, 0.333);
        }

        .card_name {
            font-size: 16px;
            margin-bottom: 0;
        }
        .card_prof {
            font-size: 8px;
            letter-spacing: 1px;
        }
        .card_year {
            font-size: 9px;
            bottom: 20px;
            right: 15px;
        }
    }
    &__right {
        flex-basis: calc(100% - 430px);
    }
}

.tabs__caption {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
}

.tabs__caption li {
    position: relative;
    padding: 10px 0;
    margin-right: 30px;
    color: #8c8c8c;
    position: relative;
    border-bottom: 3px solid transparent;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        background-color: #000;
        right: -15px;
        opacity: 0.7;
        height: 14px;
        top: calc(50% - 7px);
    }
    &:last-child {
        margin-right: 0;
        border: 0;
        &:after {
            display: none;
        }
    }
    &.active {
        border-color: #da9a81;
        color: #000;
    }
}

.tabs__caption li:not(.active) {
    cursor: pointer;
}

.tabs__content {
    display: block;
    padding-top: 35px;
}

.tabs__content.active {
    display: block;
}

.loadimg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;

    &__input {
        input {
            display: none;
        }
    }
}

.loadimg {
    margin-bottom: 60px;
}

.loadimg-view {
    flex-basis: 185px;
    margin-right: 50px;
    width: 185px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border: 1px dashed #b3babc;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &__img {
      height: 315px;
      background-image: url("../../../assets/images/bg_img.png");
    }
    &__circle {
      width: 185px;
      height: 185px;
      border-radius: 93px;
      background-image: url("../../../assets/images/bg_load.png");
    }
    &__circle_img {
      width: 185px;
      height: 185px;
      border-radius: 93px;
    }
    &__detail {
        flex-basis: 190px;
        margin-right: 30px;
    }
    &__title {
        color: #333;
        font-weight: 700;
        margin-bottom: 5px;
    }
    &__text {
        font-size: 12px;
        color: #b7b7b7;
    }
}

.labeltitle {
    color: #333333;
    font-weight: 700;
    margin-bottom: 5px;
}

.inputkab {
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    height: 50px;
    line-height: 50px;
    outline: none;
    padding: 10px 15px;
    border: 1px solid #e1e4e5;
    color: #555555;
    font-weight: 500;
    background-color: #fff;
    font-size: 14px;
    // -moz-appearance:none; /* Firefox */
    // -webkit-appearance:none; /* Safari and Chrome */
    // appearance:none;
  &:disabled{
    background-color: #d0cdcd;

  }
}

textarea.inputkab {
    resize: none;
    height: 190px !important;
    margin-bottom: 50px;
    line-height: 1.4;
    &.short {
        height: 110px;
    }
}

.tabformitemrow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.selectday {
    flex-basis: 30%;
}

.selectmonth {
    flex-basis: 35%;
}

.selectyear {
    flex-basis: 35%;
}

.selectsocial {
    flex-basis: 16%;
}

.pastelink {
    flex-basis: 62%;
}

.tabform__social {
    margin-bottom: 65px;
}

.tabform__btnn {
    flex-basis: 16%;
    .grey_link {
        font-size: 11px;
        font-weight: 500;
        padding: 16px 15px;
        letter-spacing: 1px;
    }
}

.addphotorow {
    display: flex;
    flex-wrap: wrap;
    .loadimg {
        max-width: 140px;
        margin-right: 15px;
    }
    .loadimg-view {
        margin-right: 0;
        &__detail {
            margin-right: 0;
            margin-top: 10px;
        }
        &__text {
            font-size: 11px;
        }
    }
}

.addphotoplus {
    margin-left: 70px;
    input {
        display: none;
    }
    .lightbtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        padding: 0;
        font-size: 30px;
        font-weight: 400;
        margin-top: 30px;
        color: rgba(51, 51, 51, 0.46) !important;
    }
}

.lightbtn {
    background-color: #f1f4f5;
    border-color: #f1f4f5;
    &:hover {
        background-color: #eaeaea;
    }
}

.socialline {
    border-bottom: 1px solid #e1e4e5;
    padding: 0 10px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    &__icon {
        flex-basis: 13%;
        img {
            max-height: 25px;
            width: auto;
        }
    }
    &__active {
        flex-basis: 10%;
    }
    &__link {
        flex-basis: 50%;
        color: #666;
        padding-right: 25px;
        background-image: url("../../../assets/images/edit.png");
        background-position: 99% center;
        background-repeat: no-repeat;
        background-size: inherit;
    }
    &__checkbox {
        flex-basis: 9%;
        input {
            display: none;
        }
        .greenonof {
            border-radius: 50px;
            height: 25px;
            width: 50px;
            border: 1px solid #d5d5d5;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -ms-border-radius: 50px;
            -o-border-radius: 50px;
            position: relative;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            background-color: #fff;
            display: block;
            cursor: pointer;
            &:before {
                position: absolute;
                left: 2px;
                content: "";
                top: 1px;
                display: block;
                transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -ms-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                width: 19px;
                height: 19px;
                border: 1px solid #d5d5d5;
                background-color: #fff;
            }
        }
        input:checked+.greenonof {
            background-color: #56d4a1;
            border-color: #56d4a1;
            &:before {
                right: 2px;
                left: auto;
                border-color: #56d4a1;
            }
        }
    }
    &__delete {
        flex-basis: 7%;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}

.activebtn {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 25px;
    min-width: 65px;
    padding: 5px;
    background-color: #56d4a1;
    &.inactivebtn {
        background-color: #ee4d64;
    }
}

.search_text {
    font-size: 22px;
    line-height: 1.6;
    margin-top: 30px;
    margin-bottom: 30px;
}

/*-------------------------new kabinet------------------------*/

.visibilityTitle {
    position: absolute;
    right: 28%;
    font-weight: 700;
    top: -44%;
}

.changePosterContainer {
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &-topForm {
        display: flex;
        height: 80%;
        width: 100%;
        margin: 15px 0;
    }

    &-leftForm {
        display: flex;
        flex-direction: column;
        height: 80%;
        width: 50%;
    }

    &-rightForm {
        display: flex;
        flex-direction: column;
        height: 80%;
        margin: auto;
    }

    &-bottomForm {
        display: flex;
        height: 20%;
        width: 100%;
        justify-content: space-between;
        margin: 15px 0 0 0 ;

        &-left {
            height: 100%;
            width: 30%;
            text-align:  center;

            &-btn {
                height: 100%;
                width: 100%;
                text-align:  center;
            }
        }

        &-center {
            height: 100%;
            width: 30%;
            text-align: center;
        }

        &-right {
            height: 100%;
            width: 30%;
            text-align:  center;
        }
    }

    &-title {
        display: flex;
        height: 20%;
        width: 100%;
        margin: 15px 0;
        font-weight: 700;

        &-posterMaker {
            justify-content: center;
        }
    }

    &-container {
        display: flex;
        height: 80%;
        width: 100%;
        justify-content: flex-start;

        &-posterChanger {
            justify-content: flex-end;
        }

        &_poster {
            position: relative;
            background-position: center;
            height: 315px;
            width: 185px;
            border-radius: 15px;
            box-shadow: 0 0 1.11px 1.11px rgba(70, 78, 80, 0.111);
            background-image: url("../../../assets/images/images222.jpg");

            &-img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 15px;
                border:  none;
                outline: none;
            }
        }
    }
}

.redact {
    margin-bottom: 25px;
    align-items: center;
    &__left {
        padding-right: 35px;
        flex-basis: calc(100% - 185px);
        align-items: center;
        height: 50px;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../../../assets/images/bgleft.png");
    }
    &__leftleft {
        display: flex;
        align-items: center;
        width: 50%;
    }
    &__leftarrow {
        margin-left: 10px;
        margin-right: 10px;
        height: 40px;
        width: 40px;
        background-color: #464e50;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }
    &__lefttext {
        font-size: 12px;
        color: #000;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 600;
      margin-left: 15px;
    }
    &__leftright {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: flex-end;
    }
    &__select {
        font-size: 12px;
        color: #000;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 600;
        background-image: url("../../../assets/images/selectarroww.png");
        background-repeat: no-repeat;
        background-position: 100% center;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        cursor: pointer;
        outline: none;
        margin-left: 10px;
        width: 100px;
        border: 0;
        background-color: transparent;
    }
    &__right {
        flex-basis: 180px;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        border: 1px solid #878787;
        height: 50px;
        align-items: center;
        padding-left: 2px;
        padding-right: 2px;
    }
    &__visible {
        flex-basis: calc(100% / 4);
        height: 40px;
        &_draft {
            background-image: url("../../../assets/images/draft0.png");
        }
        &_hide {
            background-image: url("../../../assets/images/hidden0.png");
        }
        &_visib {
            background-image: url("../../../assets/images/visible_all0.png");
        }
        &_group {
            background-image: url("../../../assets/images/visible_ff0.png");
        }
        span {
            cursor: pointer;
            display: block;
            width: 100%;
            height: 40px;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            -ms-border-radius: 100px;
            -o-border-radius: 100px;
            background-color: transparent;
        }
        input {
            display: none;
        }
    }
}

.formsection {
    &__title {
        margin-bottom: 20px;
        border-bottom: 1px solid #f2f1f6;
        padding-bottom: 10px;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
        font-weight: 700;
        background-image: url("../../../assets/images/arr2.png");
        background-repeat: no-repeat;
        background-position: 97% 8px;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        &:hover {
            color: #000;
        }
    }
}

.tabform__row_left {
    justify-content: flex-start !important;
}

.addmorefaq {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
    &__ins {
        cursor: pointer;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        font-size: 14px;
        color: #b1b1b1;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        background-color: #f2f1f6;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        &:hover {
            color: #000;
        }
    }
}

.modal_button {
    margin-bottom: 25px;
}

.grey_link_disabled {
    background-color: #b6b5b5 !important;
    border-color: #d7d7d7 !important;
    &:hover {
      background-color: #c2c2c2 !important;
        //color: rgb(163, 33, 33) !important;
    }
}

.many_grey_link {
    margin-left: 25px;
}
.grey_link_cancel {
    margin-left: 25px;
    background-color: #b6b5b5 !important;
    border-color: #d7d7d7 !important;
    &:hover {
      background-color: #c2c2c2 !important;
        //color: rgb(163, 33, 33) !important;
    }
}

.mapiframe {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
    margin-bottom: 50px;
    iframe {
        height: 280px !important;
        width: 100% !important;
    }
}

.loadimg-view_small {
    width: 140px;
    height: 110px;
}

.addphotorow_mt {
    margin-top: 15px;
}

.formsection__content_mb {
    margin-bottom: 50px;
}

.takemoney {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    background-color: #f2f1f6;
    padding: 35px 30px 20px 20px;
    .tabform {
        &__item {
            flex-basis: 50.5%;
        }
    }
    .inputkab {
        background-color: transparent;
    }
    .tabform__item_select {
        flex-basis: 12.5%;
    }
}

.treegenet {
    margin-top: 20px;
    &__item {
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__img {
        flex-basis: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            display: none;
        }
        span {
            cursor: pointer;
            display: block;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border: 6px solid #c5c5c5;
            background-color: #f1f4f5;
            width: 130px;
            height: 130px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("../../../assets/images/bg_load.png");
        }
    }
    &__right {
        flex-basis: calc(100% - 165px);
    }
    &__more {
        margin-left: 165px;
    }
    &__morebtn {
        background-color: #464e50;
    }
    .tabform__item {
        margin-bottom: 20px;
    }
}

.treegenet_name {
    flex-basis: 48%;
}

.treegenet_surname {
    flex-basis: 31.3%;
}

.treegenet_arrow {
    flex-basis: 15%;
}

.tabform__row_toppp {
    margin-top: 50px;
}

.tabform__circle {
    .labeltitle {
        margin-bottom: 20px;
    }
    .treegenet {
        &__img {
            justify-content: flex-start;
            margin-right: 50px;
            span {
                border: 0;
                background-image: url("../../../assets/images/loadphoto.png");
            }
        }
    }
    .saveavatar {
        margin-bottom: 10px;
    }
}

.payitem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 25px 30px 25px 30px;
    border: 1px solid #eaeaea;
    &__card {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 43%;
    }
    &__img {
        margin-right: 30px;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border: 1px solid #eaeaea;
        overflow: hidden;
        width: 96px;
        height: 165px;
        img {
            width: 100%;
        }
    }
    &__cardright {
        flex-basis: calc(100% - 130px);
        padding-top: 25px;
    }
    &__title {
        color: #676767;
        font-size: 16px;
        font-weight: 700;
    }
    &__descr {
        margin-top: 35px;
        color: #2b2929;
        line-height: 1.7;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
    }
    &__period {
        flex-basis: 165px;
    }
    &__periodinside {
        width: 185px;
        height: 45px;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
    }
    &__count_input {
        margin-top: 7px;
    }
    &__minus {
        color: #1a1a1a;
        font-size: 20px;
        font-weight: 700;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        cursor: pointer;
        flex-basis: 20px;
        text-align: center;
        user-select: none;
        &:hover {
            opacity: 0.7;
        }
    }
    &__center {
        flex-basis: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            font-weight: 500;
            outline: none;
            background-color: transparent;
            border: 0;
            width: 22px;
            font-size: 15px;
            color: #1a1a1a;
        }
        span {
            font-weight: 500;
            color: #1a1a1a;
            font-size: 15px;
        }
    }
    &__plus {
        text-align: center;
        cursor: pointer;
        flex-basis: 20px;
        color: #1a1a1a;
        font-size: 20px;
        font-weight: 700;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        user-select: none;
        &:hover {
            opacity: 0.7;
        }
    }
    &__money {
        text-align: center;
        flex-basis: 20%;
        color: #f5583e;
        font-weight: 500;
        font-size: 17px;
    }
    &__close {
        cursor: pointer;
        span {
            width: 30px;
            height: 30px;
            //border: 1px solid #eaeaea;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            &:hover {
                background-color: #ececec;
            }
        }
    }
}

.memorial-is-payed {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &__text {
        text-align: center;
        color: #fff;
        font-size: 20px;
        line-height: 1.5;
        margin: 0 20px;
    }
}


/*-------------------------payment------------------------*/

.yourorder {
    margin-top: 35px;
    &__left {
        flex-basis: calc(50% - 5px);
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    &__top {
        margin-bottom: 110px;
        padding-left: 30px;
    }
    &__title {
        margin-bottom: 80px;
        color: #2d2c40;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
    }
    &__row {
        margin-bottom: 35px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #646a7c;
        &_header {
            text-transform: uppercase;
            color: #2d2c40;
            .yourorder__name {
                font-weight: 600;
            }
            .yourorder__value {
                font-weight: 600;
            }
        }
    }
    &__name {
        flex-basis: 64.5%;
    }
    &__value {
        flex-basis: 35%;
        &_red {
            color: #f55e44;
            font-size: 14px;
            font-weight: 600;
        }
    }
    &__descr {
        padding-left: 20px;
        font-size: 14px;
        color: #7a7992;
        line-height: 1.7;
        margin-bottom: 55px;
    }
    &__footer {
        align-items: center;
    }
    &__arrow {
        line-height: 0;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: #2c2d31;
        span {
            line-height: 0;
        }
    }
    &__right {
        flex-basis: calc(50% - 5px);
        padding-left: 35px;
    }
    &__righttext {
        margin-right: 10px;
        text-transform: uppercase;
        font-size: 12px;
        color: #000000;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    &__righttotal {
        color: #f55e44;
        font-size: 36px;
        font-weight: 700;
    }
}

.ordergrey {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    background-color: #494c57;
    padding: 25px 20px;
    &__title {
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 20px;
    }
    &__choise {
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;
        margin-right: 50px;
        select {
            outline: none;
            background-color: transparent;
            border: 0;
            color: #d9d713;
            font-size: 13px;
            font-weight: 600;
            padding-right: 5px;
            text-align: right;
            padding-bottom: 3px;
        }
    }
    &__cards {
        margin-bottom: 20px;
        margin-left: -55px;
    }
    &__inprow {
        margin-bottom: 20px;
    }
    &__inptitle {
        font-size: 13px;
        color: #b0b0b0;
        font-weight: 600;
    }
    &__inpinput {
        outline: none;
        width: 100%;
        background: transparent !important;
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 10px;
        color: #fff !important;
        border: 0;
        padding-top: 10px;
        border-bottom: 1px solid #b0b0b0;
        &::placeholder {
            color: silver;
        }
    }
    &__tworow {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .ordergrey__inprow_bigger {
            flex-basis: 66%;
        }
        .ordergrey__inprow_smaller {
            flex-basis: 30%;
        }
    }
    &__twoselect {
        display: flex;
        justify-content: space-between;
        .ordergrey__inpinput {
            flex-basis: 47%;
        }
    }
    &__btn {
        margin-top: 30px;
        margin-bottom: 15px;
        width: 100%;
        outline: none;
        border: 0;
        background-color: #03a54f;
        color: #fff;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        height: 50px;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 4px;
        cursor: pointer;
        &:hover {
            background-color: #0e6637;
        }
    }
}

.cardblock {
    width: 340px;
    background-color: #363943;
    background-size: cover;
    background-position: center;
    padding: 30px 30px 25px 30px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    &__type {
        margin-bottom: 30px;
    }
    &__code {
        letter-spacing: 2px;
        margin-bottom: 40px;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
    }
    &__bottom {
        color: #fff;
        font-size: 18px;
        font-weight: 700;
    }
}

.yourorder__rightre {
    justify-content: flex-end;
    align-items: center;
}

.popupmes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    &__inside {
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        position: relative;
        border: 4px solid #379b0f;
        padding-top: 55px;
        text-align: center;
        background: #e3e3e3;
        max-width: 620px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    &__close {
        cursor: pointer;
        position: absolute;
        right: 35px;
        width: 32px;
        height: 32px;
        top: 20px;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        svg {
            width: 100%;
        }
        &:hover {
            opacity: 0.7;
        }
    }
    &__text {
        margin-top: 45px;
        margin-bottom: 75px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: #379b0f;
        letter-spacing: 2px;
    }
    &__list {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: #379b0f;
        letter-spacing: 2px;
    }
    &_red {
        .popupmes__inside {
            border-color: #ee2230;
        }
        .popupmes__text {
            color: #ee2230;
        }
        .popupmes__list {
            color: #ee2230;
        }
    }
    &_yellow {
        .popupmes__inside {
            border-color: #fe7d02;
        }
        .popupmes__text {
            color: #fe7d02;
        }
    }
}

.imagesave {
    width: 100%;
    text-align: center;
    .alert-alert{
      background: #d58779;
    }
    .alert-notice{
      background: #b2d579;
    }
    &__inside {
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        width: 100%;
        background: #b2d579;
        padding-left: 35px;
        padding-right: 30px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__text {
        flex-basis: calc(100% - 60px);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        letter-spacing: 2px;
    }
    &__close {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        line-height: 0;
        &:hover {
            opacity: 0.7;
        }
    }
}

.mapContainerElement {
    height: 358px;
    width: 100%;
}

.googleMaps {
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

.memomovie_settings {
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 25px;
}


/*-------------------------adaptive------------------------*/
@media (min-width:2700px) {
    .first_slider {
        margin-top: 200px;
    }
}

@media screen and (max-width:1600px) {

    .memorial_can_be {
        padding-bottom: 100px;
    }
    .cards_ul li {
        margin: 0 8px;
    }

    .item_slider_text {
        font-size: 20px;
    }
}

@media screen 
    and ( min-width: 1300px )
    and( max-width: 1399px ) {
    .redact__left {
        padding-right: 10px;
    }

    .cardnumber {
        position: absolute;
        height: 20px;
        width: 20px;
    }
    
    .card_img {
        height: 100%;
    }

    .text-shadow {
        font-size: 10px;
    }
    
    .tabform__item {
        flex-basis: 45%;
    }

    .tabform__row_toppp {
        flex-direction: column;
        margin-top: 0;
    }

    .tabform__row {
        margin: 15px 0;
    }

    .btn1024 {
        width: 100%;
        margin: 5px 0;
        text-align: center;
    }

    .link1024 {
        display: flex;
        justify-content: center;
    }

    .redact__lefttext {
        font-size: 10px !important;
    }

    .redact__righttext {
        font-size: 10px !important;
    }

    .kabinetrow {
        display: flex;
    }

    .kabinetrow__left {
        width: 30%;
        flex-basis: 350px;
        height: 660px;
    }

    .kabinetrow__right {
        flex-basis: calc(100% - 380px);
    }

    .mapContainerElement {
        height: 250px !important;
    }

    .photoslider_jsitem{
        display: flex;
        flex-direction: column;
        height: 400px;
        padding: 0 !important;
    }

    .photoslider_jsitem a {
        height: 50%;
    }

    .photoslider {
        margin: auto;
    }

    .toPersonalArea {
        padding: 16px 20px;
        font-size: 10px;
    }

    .toMemoPageBackBtn {
        display: flex;
        justify-content: center;
        max-width: 150px;
        font-size: 10px;
        margin-left: auto;
        margin-right: auto;
    }

    .text-shadow {
        font-size: 16px !important;
    }
    .logo {
        font-size: 20px;
    }

    .containerBe1300 {
        margin: 0 15px;
        margin: auto;
    }
    .born_year,
    .passed_year {
        flex-basis: 16%;
    }
    .card_name,
    .back_name,
    .back_years {
        font-size: 21px;
    }
    .line_head .memor_price_left {
        font-size: 46px;
    }
    .line_head .per_cents {
        font-size: 27px;
    }
    .cards_ul li {
        width: 42px;
        margin: 7px 8px 0;
    }
}

@media screen 
    and ( min-width: 1000px )
    and( max-width: 1101px ) {

    .redact__left {
        padding-right: 10px;
    }

    .cardnumber {
        position: absolute;
        height: 20px;
        width: 20px;
    }

    .num1 {
        left: 20%;
    }

    .num2 {
        left: 40%;
    }

    .num3 {
        left: 60%;
    }

    .num4 {
        left: 80%;
    }

    .numM {
        left: 80%;
    }

    .card_img {
        height: 100%;
    }

    .text-shadow card_name ng-binding {
        font-size: 10px;
    }
    
    .tabform__item {
        flex-basis: 45%;
    }

    .tabform__row_toppp {
        flex-direction: column;
        margin-top: 0;
    }

    .tabform__row {
        margin: 15px 0;
    }

    .btn1024 {
        width: 100%;
        margin: 5px 0;
        text-align: center;
    }

    .link1024 {
        display: flex;
        justify-content: center;
    }

    .redact__lefttext {
        font-size: 10px !important;
    }

    .redact__righttext {
        font-size: 10px !important;
    }

    .kabinetrow {
        display: flex;
        flex-wrap: wrap;
    }

    .kabinetrow__left {
        width: 30%;
        flex-basis: 300px;
        height: 660px;
    }

    .kabinetrow__right {
        flex-basis: calc(100% - 330px);
    }

    .mapContainerElement {
        height: 250px !important;
    }

    .photoslider_jsitem{
        display: flex;
        flex-direction: column;
        width: 166px;
        height: 320px;
        padding: 0 !important;
    }

    .photoslider_jsitem a {
        margin: auto;
        height: 50%;
    }

    .photoslider {
        margin: auto;
    }

    .toPersonalArea {
        padding: 16px 20px;
        font-size: 10px;
    }

    .toMemoPageBackBtn {
        display: flex;
        justify-content: center;
        max-width: 150px;
        font-size: 10px;
        margin-left: auto;
        margin-right: auto;
    }

    .front_shadow {
        padding: 20px 15px !important;
    }

    .front_year {
        font-size: 10px !important;
    }

    .text-shadow {
        font-size: 16px !important;
    }

    .back_text {
        font-size: 10px !important;
    }

    .catalog_back {
        width: 180px;
    }

    .logo {
        font-size: 20px;
    }

    .born_year,
    .passed_year {
        flex-basis: 16%;
    }
    .card_name,
    .back_name,
    .back_years {
        font-size: 21px;
    }
    .line_head .memor_price_left {
        font-size: 46px;
    }
    .line_head .per_cents {
        font-size: 27px;
    }
    .memorial_can_be {
        padding-bottom: 50px;
    }
    .cards_ul li {
        width: 42px;
        margin: 7px 8px 0;
    }
}

@media screen and (max-width:1300px) {
    .tabform__submit .btn1024 {
        width: 100%;
        text-align: center;
    }

    .cards_row.active {
        height: 900px;
        margin-bottom: 40px;
    }
    .back_years {
        margin-bottom: 90px;
    }
    .item_slider_text {
        font-size: 18px;
    }
    .socialline__delete {
        flex-basis: 4%;
    }
    .socialline__link {
        flex-basis: 55%;
        font-size: 12px;
        line-height: 1.5;
    }
    .tabform__btnn .grey_link {
        padding: 14px 7px;
    }
    .selectsocial {
        flex-basis: 19%;
    }
    .inputkab {
        height: 45px;
        line-height: 45px;
        padding: 5px 10px;
        font-size: 13px;
    }
    .socialline {
        padding: 0;
    }
    .ficus_row__title {
        font-size: 20px;
    }
    .ficus {
        padding-bottom: 20px;
    }
    .recentlinks {
        padding-left: 0;
        a {
            font-size: 14px;
        }
    }
    .tribute__input {
        flex-basis: 70%;
    }
    .tribute .tributeinp__submit {
        flex-basis: 27%;
    }
    .kabinetrow__right {
        max-width: 800px;
        flex-basis: 100% !important;
    }

    .kabinetrow {
        gap: 40px;
    }
}

@media screen and (max-width: 1120px) {
    .search_text {
        font-size: 20px;
    }
    .first_slider {
        margin-top: 0px;
        max-width: 330px;
    }
    .card_name,
    .back_name,
    .back_years {
        font-size: 19px;
    }
    .card_year {
        bottom: 15px;
        right: 15px;
    }
    .card_name,
    .back_name,
    .back_years {
        font-size: 17px;
    }
    .back_years {
        margin-bottom: 50px;
    }
    .cards_row.active {
        height: 820px;
    }
    .line_head .memor_price_left {
        font-size: 28px;
    }
    .line_head .per_cents {
        font-size: 21px;
    }
    .line_head .per_month {
        font-size: 11px;
    }
    .line_head .memor_title {
        font-size: 16px;
    }
    .line_a .grey_link {
        margin-top: 25px;
        margin-bottom: 30px;
        width: auto;
    }
    .grey_link {
        padding: 12px;
        font-size: 11px;
    }
    .cards_row .grey_link {
        font-size: 11px;
        padding: 12px 30px;
    }
    .activetrigger_demo2 {
        font-size: 11px;
    }
    .born_year,
    .passed_year {
        flex-basis: 45%;
        margin-bottom: 30px;
    }
    .sidebar_form .select_month,
    .sidebar_form .select_day,
    .sidebar_form .select_year {
        flex-basis: 100%;
        .dropdownvisible_demo2 {
            max-width: 196px;
        }
    }
    .trigger_demo2,
    .sidebar_form .input_form {
        font-size: 11px;
        letter-spacing: 2px;
    }
    .sidebar_form .select_month .dropdownvisible_demo2,
    .sidebar_form .select_day .dropdownvisible_demo2,
    .sidebar_form .select_year .dropdownvisible_demo2 {
        max-width: 196px;
    }
    .sidebar_title {
        margin-bottom: 30px;
    }
    .sidebar_list {
        margin-bottom: 20px;
    }

    .kabinetrow {
        justify-content: center;
        flex-wrap: wrap;
    }

    .kabinetrow__title {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .tabs__caption li {
        padding: 5px 0;
        margin-right: 30px;
        font-size: 13px;
    }
    .tabs__content {
        padding: 30px;
    }
    .loadimg-view {
        margin-right: 20px;
    }
    .loadimg-view__detail {
        flex-basis: 160px;
        margin-right: 20px;
    }
    .loadimg-view__title {
        font-size: 13px;
    }
    .loadimg-view__text {
        font-size: 12px;
    }
    .labeltitle {
        font-size: 12px;
    }
    .loadimg {
        margin-bottom: 40px;
    }
    .tabform__item {
        flex-basis: 49%;
        margin-bottom: 20px;
    }
    .inputkab {
        height: 40px;
        line-height: 40px;
        padding: 5px 10px;
        font-size: 12px;
    }
    .selectday,
    .selectmonth {
        flex-basis: 26%;
    }
    textarea.inputkab {
        height: 150px;
        margin-bottom: 30px;
    }
    .addphotoplus {
        margin-left: 10px;
    }
    .selectsocial {
        flex-basis: 24%;
    }
    .pastelink {
        flex-basis: 57%;
    }
    .tabform__btnn {
        flex-basis: 17%;
    }
    .tabform__btnn .grey_link {
        font-size: 9px;
        padding: 13px 5px;
    }
    .socialline__link {
        flex-basis: 100%;
        order: 3;
        margin-top: 15px;
    }
    .socialline__icon {
        flex-basis: 16%;
    }
    .socialline__active {
        flex-basis: 20%;
    }
    .socialline__checkbox {
        flex-basis: 17%;
    }
    .socialline__delete {
        flex-basis: 7%;
        text-align: right;
        padding-right: 5px;
    }
    .tabform__social {
        margin-bottom: 30px;
    }
    .volume__img {
        max-width: 30px;
    }
    .volume {
        width: 100%;
    }

    .memomovie_buttons {
        flex-wrap: wrap;
    }

    .memomovie_settings {
        flex-wrap: wrap;
    }

    .coowner_container_navigation {
        flex-wrap: wrap;
        width: 100vw;

        .coowner_container_navigation_button {
            width: 100%;
        }
    }
}

@media screen and (max-width:760px) and ( max-width: 1120px ) {
    .search_text {
        font-size: 16px;
        margin: 20px 0;
    }
    .slick-slider {
        text-align: right;
    }
    .item_slider_text {
        font-size: 16px;
    }
    .item_slider_number {
        font-size: 55px;
    }
    .cards_row.active {
        height: 775px;
    }
    .show_more_cards {
        height: 60px;
        padding-left: 2px;
        width: 60px;
        img {
            max-width: 37px;
        }
    }
    .adress_text {
        font-size: 12px;
    }
    .line_head .memor_title {
        font-size: 12px;
    }
    .line_head .memor_price_left {
        font-size: 22px;
    }
    .line_head .per_cents {
        font-size: 16px;
    }
    .line_head {
        flex-wrap: inherit;
    }
    .line_head .per_month {
        font-size: 9px;
    }
    .grey_link {
        padding: 11px;
        font-size: 8px;
    }
    .line_head .memor_price {
        padding: 18px 2px 13px;
    }
    .breadcrumbbe ul li {
        padding: 0 15px;
    }
    .content_sidebar {
        padding-top: 0;
    }
    .sidebar_list {
        .trigger_demo2 {
            padding: 8px 0;
        }
    }
    .yellow_title {
        margin-bottom: 5px;
    }
    h2 {
        font-size: 30px;
    }
    p strong {
        font-size: 16px;
        line-height: 1.38;
    }
    p {
        margin-bottom: 15px;
        line-height: 1.4;
    }
    .kabinetrow__right {
        max-width: 800px;
        flex-basis: 100%;
        margin-top: 10px;
    }
    .sidebar_title {
        margin-bottom: 0;
    }
    .tabform__circle .treegenet__img {
        margin-right: 20px;
    }
    .moder__itemvideo {
        width: 190px;
        height: 120px;
    }
    .moder__itemvideo iframe {
        width: 190px !important;
        height: 120px !important;
    }
    .takemoney {
        padding: 15px;
    }
    .formsection__content_mb {
        margin-bottom: 35px;
    }
    .takemoney .tabform__item,
    .takemoney .tabform__item_select {
        flex-basis: 100%;
    }
    .redact__left {
        flex-basis: 100%;
        margin-bottom: 30px;
        padding-right: 15px;
    }
    .loadimg-view_big {
        flex-basis: 150px;
    }
    .payitem {
        padding: 20px;
    }
    .payitem__card {
        margin-bottom: 20px;
    }
    .yourorder__left,
    .yourorder__right {
        flex-basis: 100%;
    }
    .yourorder__title {
        margin-bottom: 40px;
    }
    .yourorder__top {
        margin-bottom: 0;
    }
    .yourorder__descr {
        margin-bottom: 25px;
    }
    .yourorder__row {
        margin-bottom: 20px;
    }
    .yourorder__footer {
        margin-bottom: 40px;
    }
    .cardnumber {
        width: 25px;
        height: 25px;
        font-size: 12px;
    }
    .imagesave__inside {
        padding-left: 20px;
        padding-right: 10px;
    }
    .imagesave__text {
        font-size: 12px;
    }
    .popupmes {
        padding: 10px;
    }
    .popupmes__inside {
        padding-top: 40px;
    }
    .popupmes__close {
        right: 10px;
        top: 10px;
    }
    .popupmes__text {
        margin-top: 40px;
        margin-bottom: 60px;
        font-size: 13px;
    }
    .popupmes__list {
        font-size: 13px;
    }
    .imagesave {
        position: relative;
        top: 0;
        margin-bottom: 30px;
    }
}

@media screen and (max-width:460px) {
    .redact__lefttext, .redact__select {
        font-size: 7px;
    }

    .changePosterContainer-bottomForm .cancel {
        width: 48%;
    }

    .changePosterContainer-bottomForm-left {
        width: 48%;
    }

    .slick-slider {
        margin-top: 20px;
    }
    .item_slider_number {
        font-size: 40px;
    }
    .item_slider_text {
        font-size: 14px;
    }
    .cards_row.active {
        height: 1540px;
    }
    .line_head {
        flex-wrap: wrap;
    }
    .memorial_can_be {
        overflow: inherit;
    }
    .line_head .memor_title {
        font-size: 11px;
    }
    .memorial_can_be {
        padding-bottom: 20px;
    }
    h2 {
        font-size: 21px;
    }
    p strong {
        font-size: 15px;
    }
    .kabinetrow__title {
        font-size: 18px;
    }
    .tabs__caption li {
        margin-right: 20px;
        font-size: 12px;
        border-bottom: 2px solid transparent;
        letter-spacing: 1px;
    }
    .socialline__link {
        font-size: 11px;
    }
    .loadimg-view {
        flex-basis: 100px;
        height: 75px;
        margin-right: 10px;
    }
    .loadimg-view__detail {
        flex-basis: 95px;
        margin-right: 10px;
    }
    .loadimg-view__title {
        font-size: 11px;
    }
    .loadimg-view__text {
        font-size: 9px;
    }
    .loadimg {
        margin-bottom: 25px;
    }
    .tabform__item {
        flex-basis: 100%;
        margin-bottom: 10px;
    }
    .addphotorow .loadimg {
        max-width: 100px;
        margin-right: 10px;
    }
    .addphotorow .loadimg-view__text {
        font-size: 9px;
    }
    .addphotorow .loadimg-view {
        width: 80px;
        height: 60px;
    }
    .addphotoplus .lightbtn {
        width: 35px;
        height: 35px;
        margin-top: 10px;
    }
    .addphotoplus {
        margin-left: 0;
    }
    .pastelink {
        flex-basis: 100%;
        order: 3;
        margin-top: 10px;
    }
    .selectsocial {
        flex-basis: 46%;
    }
    .tabform__btnn {
        flex-basis: 35%;
        text-align: right;
    }
    .kabinetrow__left .card_name {
        font-size: 15px;
    }
    .tabs__caption li:after {
        right: -11px;
    }
    .moder__header ul a {
        margin-left: 12px;
        font-size: 13px;
    }
    .moder__content {
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .moder__item {
        margin-bottom: 40px;
    }
    .moder__itemins {
        max-width: 120px;
        max-height: 90px;
    }
    .tabform {
        .inputkab {
            height: 36px;
            line-height: 34px;
        }
    }
    .treegenet__img {
        flex-basis: 130px;
    }
    .tabform__circle .saveavatar {
        text-align: center;
    }
    .loadimg-view__detail {
        flex-basis: 120px;
    }
    .loadimg-view_big {
        flex-basis: 100px !important;
    }
    .loadimg-view__detail_small {
        flex-basis: 94px;
    }
    textarea.inputkab.short {
        height: 50px;
    }
    .treegenet__item {
        flex-direction: column;
    }
    .treegenet__more {
        margin-left: auto;
    }
    .tabform__row_toppp {
        margin-top: 50px;
    }
    .redact__right {
        height: 40px;
        margin-left: auto;
        margin-right: auto;
    }
    .redact__visible {
        height: 35px;
    }
    .redact__visible span {
        height: 35px;
    }
    .redact__left {
        height: auto;
        padding-top: 10px;
        padding-bottom: 15px;
        flex-direction: column;
        padding-right: 0;
    }
    .redact__leftleft {
        margin-bottom: 10px;
        text-align: center;
    }
    .redact__leftarrow {
        display: none;
    }
    .yourorder__right {
        padding-left: 20px;
    }
    .ordergrey__cards {
        margin-left: -35px;
    }
    .ordergrey {
        padding: 15px;
    }
    .cardblock {
        width: 270px;
        padding: 25px 20px 15px 20px;
    }
    .yourorder__top {
        padding-left: 10px;
    }
    .yourorder__row {
        font-size: 12px;
    }
    .yourorder__descr {
        padding-left: 10px;
        font-size: 12px;
    }
    .yourorder__righttext {
        font-size: 11px;
        letter-spacing: 1px;
    }
    .yourorder__righttotal {
        font-size: 27px;
    }
    .ordergrey__title {
        font-size: 18px;
    }
    .ordergrey__choise {
        margin-right: 10px;
    }
    .ordergrey__btn {
        height: 40px;
        margin-top: 15px;
    }
    .cardblock__type {
        margin-bottom: 28px;
    }
    .cardblock__bottom {
        font-size: 16px;
    }
    .cardblock__code {
        margin-bottom: 20px;
    }
    .ordergrey__inpinput {
        font-size: 12px;
        padding-bottom: 6px;
        padding-top: 6px;
    }
    .payitem {
        padding: 10px;
        margin-bottom: 15px;
        position: relative;
        flex-wrap: wrap;
    }
    .payitem__card {
        flex-basis: 100%;
    }
    .payitem__img {
        width: 85px;
        margin-right: 20px;
        height: 145px;
    }
    .payitem__title {
        font-size: 15px;
    }
    .payitem__descr {
        margin-top: 25px;
    }
    .payitem__periodinside {
        width: 120px;
        height: 35px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .payitem__center input {
        width: 17px;
        font-size: 13px;
    }
    .payitem__center span {
        font-size: 13px;
    }
    .payitem__plus {
        line-height: 1;
    }
    .ordergrey__inptitle {
        font-size: 12px;
    }
    .payitem__period {
        flex-basis: 120px;
    }
    .imagesave__text {
        font-size: 11px;
        flex-basis: calc(100% - 40px);
    }
}

@media screen and (max-width:410px) {
    .cards_row.active {
        height: 1385px;
    }

    .draggbleContainer {
        height: auto;
        flex-direction: column;
    }
}