@import '../../../../../styles/theme.scss';

.addressContainer {
    padding-left: 20px;
    border-left: 1px solid #17393E;

    @include tablet {
      border-left: none;
      margin: 0;
      padding: 0;
      padding-top: 20px;
      margin-top: 30px;
      text-align: center;
      border-top: 1px solid #17393e;
    }

    .subtitle {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 8px;
    }
}