@import "../../../../../styles/theme.scss";

.logoSmall {
  .image {
    display: block;
    height: 40px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 15px;

  @include tablet {
    gap: 8px;
  }

  .image {
    display: block;
    @include tablet {
      display: none;
    }
  }

  .mobileImage {
    display: none;

    @include tablet {
      display: block;
      height: 40px;
    }
  }

  .text {
    font-family: $title-font;
    color: $deep-blue-color;
    font-size: 42px;

    @include tablet {
      font-size: 23px;
      font-weight: 200;
      margin-top: 12px;
    }
  }
}
