@import '../../../styles/theme.scss';

.button {
    border: 0;
    outline: 0;
    display: block;
    width: fit-content;
    background-color: $blue-color;
    color: $white-color;
    font-size: 16px;
    padding: 22px 40px;
    border-radius: 32px;

    &:hover,
    &:focus {
        background-color: #68DBDF;
        color: $white-color;
        cursor: pointer;
    }

    @include mobile {
        font-size: 14px;
        padding: 18px;
    }
}

.deepBlue {
    background-color: #35CFD4;

    &:hover,
    &:focus {
        background-color: #40A8B4;
    }
}

.white {
    background-color: transparent;
    color: $deep-blue-color;
    border: 1px solid $deep-blue-color;
}

.round {
    @include tablet {
        padding: 14px;
        border-radius: 50px;
    }
}