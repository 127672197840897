@import '../../../styles/theme.scss';

.upArrow {
    z-index: 9999999;
    position: fixed;
    display: flex; 
    justify-content: center; 
    right: 40px;
    bottom: 100px;
    width: 68px; 
    height: 68px; 
    border-radius: 50%; 
    background-color: $blue-color; 
    cursor: pointer;

    @include mobile {
      bottom: 20px;
      right: 20px;
    }

    &:hover {
        background-color: #68DBDF;
    }
}

.arrow {
    margin: auto,;
    color: white;
}