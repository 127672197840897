.littleSpinner {
    display: flex;
    height: 100%;
    width: 100%;

    &-circle {
        margin: auto;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 5px solid rgba(133, 180, 186, 0.3);
        animation: spinner 1s linear infinite
    }
}

@keyframes spinner {
    0% {
        border-top: 5px solid rgba(133, 180, 186, 1);
    }

    25% {
        border-right: 5px solid rgba(133, 180, 186, 1);
    }

    50% {
        border-bottom: 5px solid rgba(133, 180, 186, 1);
    }

    75% {
        border-left: 5px solid rgba(133, 180, 186, 1);
    }
    
    100% {
        border: 5px solid rgba(133, 180, 186, 0.3);
    }
}