.overflowspinner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    display: flex;
    height: 100vh;
    width: 100%;
    background-color: white;

    &-spinner {
        height: 75px;
        width: 75px;
        margin: auto;
        border-radius: 50%;
        border: 7px solid rgba(107, 173, 182, 0.333);
        animation: spinn 1s linear infinite;
    }
}

@keyframes spinn {
    0% {
        border: 7px solid rgba(107, 173, 182, 0.333);
    }

    25% {
        border-top: 7px solid #7faab0;
    }

    50% {
        border-right: 7px solid #7faab0;
    }

    75% {
        border-bottom: 7px solid #7faab0;
    }

    100% {
        border-left: 7px solid #7faab0;
    }
}