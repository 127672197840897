.createEternalMemory {
  margin-top: 25px;
  text-align: center;
  background-color: #4a666b;
}

.ficusrange__price {
  font-size: 18px;
  color: #f3f3f7;
  margin-bottom: 15px; }
.ficusrange__inside {
  position: relative;
  height: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
.ficusrange__inside .lineaply {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 60%;
  height: 10px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #18a4d0;
  background: linear-gradient(176deg, #18a4d0 0%, #52e2c1 100%); }
.ficusrange__inside .ficusdot {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  left: 55%;
  background-color: rgba(80, 224, 193, 0.5);
  top: calc(50% - 12px); }
.ficusrange__inside .ficusdot span {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #50e0c1;
  display: block;
  width: 15px;
  height: 15px; }
.ficusrange__percent {
  font-size: 16px;
  font-weight: 700;
  color: #f3f3f7;
  margin-bottom: 15px;
  position: absolute;
  top: -35px;
  left: 55%; }
.imagesave {
  width: 100%;
  text-align: center;
  .alert-alert{
    background: #d58779;
  }
  .alert-notice{
    background: #b2d579;
  }
  &__inside {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    width: 100%;
    background: #b2d579;
    padding-left: 35px;
    padding-right: 30px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    flex-basis: calc(100% - 60px);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 2px;
  }
  &__close {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    line-height: 0;
    &:hover {
      opacity: 0.7;
    }
  }
}