@import "./reset-styles.scss";
@import "./theme.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.text-bold {
  font-weight: 700;
}

.mv-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.back_text,
.cardtextdet__inside,
.sidebarEpithas,
input,
textarea {
  cursor: text;
  user-select: text;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Montserrat:wght@300;400;500;600;700;800&family=Oswald:wght@400;500;700&family=Roboto:wght@400;500&display=swap");
body {
  line-height: 1.25;
  background-color: #fff;
  color: #464e50;
  font-size: 14px;
  overflow-x: hidden;
}

ol,
// ul {
//   list-style: none;
// }

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

input,
select,
.item_slider,
button {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body * {
  box-sizing: border-box;
  font-weight: 400;
  font-style: normal;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

body {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7.77px;

    &-thumb {
      background-color: #7faab0;
      outline: 1px solid #7faab0;
      border-radius: 25px;
      box-shadow: 0 0 3.33px 1.11px rgba(127, 170, 176, 0.333);
    }

    &-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.111);
    }
  }
}

a,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
}

.login:hover {
  cursor: pointer;
  opacity: 0.777;
}

.header_navig a {
  margin-left: 5px;
  padding: 17px 21px;
  text-align: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #38666c;
}

.statusNavigation {
  position: fixed;
  height: 50px;
  width: 100px;
  background: rgba(133, 180, 186, 0.7);
  left: 90%;
  top: 30%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  line-height: 50px;
  cursor: pointer;

  &-button {
    color: white;
    font-weight: 700;
  }
}

.containerBe {
  max-width: 1230px;
  width: 100%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

input,
select,
.item_slider,
button {
  outline: none;
}

.d_flex {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.grey_link {
  font-weight: 700;
  color: #fefefe !important;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border: none !important;
  transition: all 0.3s ease-in-out;
  padding: 16px 35px;
  background-color: #4a666b;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  display: inline-block;

  &:disabled {
    opacity: 0.7;
  }
}

.grey_link:hover {
  background-color: rgba(106, 140, 145, 0.75);
}

.tabform {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item {
    flex-basis: 32%;
    margin-bottom: 40px;
  }
}

.red {
  color: red;
}

.input_form {
  text-transform: uppercase;
  font-size: 12px;
  background-color: transparent;
  height: 45px;
  -webkit-appearance: none;
  border-radius: 0;
  color: #000;
  font-weight: 700;
  border: 0;
  border-bottom: 1px solid #bababa;
  padding: 15px 0 14px;
  letter-spacing: 4px;
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  cursor: pointer;
}
.input_form::placeholder {
  opacity: 1;
  color: silver;
}

.sidebar_form .input_form,
.sidebar_form .trigger_demo2 {
  font-weight: 600;
}

.open_menu li a {
  padding: 10px 55px;
  font-size: 15px;
}

.page_title {
  user-select: none;
}

input[type="file"] {
  display: none;
}

.aboutUsMC {
  font-family: "Roboto", sans-serif;
}

.logo:hover {
  color: #698c90;
}

.first_screen {
  @media screen and (max-width: 460px) {
    background-image: url("../assets/images/homeBackground2.webp");
  }
}

.page_title {
  font-size: 23px;
  margin-top: 45px;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.blue_title {
  text-transform: uppercase;
  color: #85b4ba;
  font-size: 65px;
  letter-spacing: 13px;
  font-weight: 700;
  margin-bottom: 75px;
  text-align: center;
}

.first_screen {
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 600px;
  background-position: bottom center;
}

.first_screen.first_screen_page {
  background-image: url("../assets/images/catalog_bg.png");
  padding-bottom: 50px;
}

.first_screen {
  background-image: url("../assets/images/homeBackground1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

span.error {
  color: red;
}

p strong {
  color: #2a332e;
  font-size: 19px;
  font-family: "Roboto", sans-serif;
  line-height: 1.58;
}

/*-------------------------register------------------------*/
.regcontent {
  max-width: 270px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.contactcontent {
  max-width: 670px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.regcontent__title {
  text-transform: uppercase;
  color: #202020;
  text-align: center;
  margin-bottom: 35px;
  font-weight: 500;
}
.regcontent .grey_link {
  min-width: 190px;
  text-align: center;
}

.regcontentform__title {
  text-transform: uppercase;
  display: block;
  font-size: 11px;
  margin-bottom: 15px;
  font-weight: 500;
}
.regcontentform__title span {
  color: #ff000c;
  font-size: 16px;
}
.regcontentform__input {
  margin-bottom: 25px;
  width: 100%;
  border: 1px solid #e3e3e3;
  outline: none;
  background-color: transparent;
  height: 40px;
  padding: 5px 10px 5px 35px;
  color: #5f5f5f;
  font-size: 12px;
}
.regcontentform__agree {
  color: #292929;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 30px;
}
.regcontentform__agree a {
  color: #88b5b8;
}
.regcontentform__agree a:hover {
  text-decoration: underline;
}

.regcontentform__item {
  position: relative;
  background-repeat: no-repeat;
  background-size: inherit;
  background-position: 5% center;
}

.labelemail {
  background-image: url("../assets/images/icon_user.png");
}

.labelpassword {
  background-image: url("../assets/images/icon_password.png");
}

.yellowbtn {
  outline: none;
  color: #464e50 !important;
  background-color: #ffd58f;
  border-color: #ffd58f;
}
.yellowbtn:hover {
  background-color: #e2b362;
}

.darkbtn {
  outline: none;
  background-color: #464e50;
  border-color: #464e50 !important;
}
.fb_link {
  padding: 16px 25px;
  outline: none;
  background-color: #3b5998 !important;
  border-color: #18284b;
}

.fb_link:hover {
  background-color: #4365ad !important;
}
.google_link {
  outline: none;
  background-color: #fefefe !important;
  color: #595959 !important;
  border-color: #595959;
}

.google_link:hover {
  background-color: #eceaea !important;
}

.regcontentform__or {
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
  color: #88b5b8;
}

.regcontent__links {
  margin-top: 20px;
  text-align: center;
}

.forgotpassw {
  display: block;
  margin-top: 30px;
  color: #84b3ac !important;
  font-size: 12px;
  text-decoration: underline;
}
.forgotpassw:hover {
  text-decoration: none;
}

.regcontentform__item_checkbox {
  text-align: center;
  display: block;
  margin-bottom: 30px;
  font-size: 12px;
  color: #5f5f5f;
}
.regcontentform__item_checkbox input {
  display: none;
}
.regcontentform__item_checkbox .regcontentform__checkbox {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.regcontentform__item_checkbox .regcontentform__checkbox span {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border: 1px solid #e3e3e3;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.regcontentform__item_checkbox input:checked + .regcontentform__checkbox span {
  background-image: url("../assets/images/checked.png");
}

/*-------------------------adaptive------------------------*/
@media screen and (min-width: 3000px) {
  .homeFirstScreenContainer {
    margin: 500px 0;
  }
}

@media screen and (max-width: 1300px) {
  .cards_row.active {
    height: 1307px;
  }

  .born_year,
  .passed_year {
    flex-basis: 16%;
  }

  .card_name,
  .back_name,
  .back_years {
    font-size: 21px;
  }

  .line_head .memor_price_left {
    font-size: 46px;
  }

  .line_head .per_cents {
    font-size: 27px;
  }

  .memorial_can_be {
    padding-bottom: 50px;
  }

  .cards_ul li {
    width: 42px;
    margin: 7px 8px 0;
  }

  .sidebarBe_fixed.active {
    left: 20px;
  }

  .maincontent {
    padding: 0px 0 30px;
  }
}

@media screen and (max-width: 1400px) {
  .sidebarBe_fixed.active {
    top: 50px;
  }

  .cards_row.active {
    height: 1090px;
    margin-bottom: 40px;
  }

  .back_years {
    margin-bottom: 90px;
  }

  .item_slider_text {
    font-size: 18px;
  }

  .socialline__delete {
    flex-basis: 4%;
  }

  .socialline__link {
    flex-basis: 55%;
    font-size: 12px;
    line-height: 1.5;
  }

  .tabform__btnn .grey_link {
    padding: 14px 7px;
  }

  .selectsocial {
    flex-basis: 19%;
  }

  .inputkab {
    height: 45px;
    line-height: 45px;
    padding: 5px 10px;
    font-size: 13px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 980px) {
  .search_text {
    font-size: 20px;
  }

  .first_slider {
    margin-top: 0px;
    max-width: 330px;
  }

  .card_name,
  .back_name,
  .back_years {
    font-size: 19px;
  }

  .card_year {
    bottom: 15px;
    right: 15px;
  }

  .card_name,
  .back_name,
  .back_years {
    font-size: 17px;
  }

  .back_years {
    margin-bottom: 50px;
  }

  .cards_row.active {
    height: 820px;
  }

  .grey_link {
    padding: 12px;
    font-size: 11px;
  }

  .cards_row .grey_link {
    font-size: 11px;
    padding: 12px 30px;
  }

  .activetrigger_demo2 {
    font-size: 11px;
  }

  .born_year,
  .passed_year {
    flex-basis: 45%;
    margin-bottom: 30px;
  }

  .sidebar_form .select_month,
  .sidebar_form .select_day,
  .sidebar_form .select_year {
    flex-basis: 100%;
  }
  .sidebar_form .select_month .dropdownvisible_demo2,
  .sidebar_form .select_day .dropdownvisible_demo2,
  .sidebar_form .select_year .dropdownvisible_demo2 {
    max-width: 196px;
  }

  .trigger_demo2,
  .sidebar_form .input_form {
    font-size: 11px;
    letter-spacing: 2px;
  }

  .sidebarBe {
    flex-basis: 240px;
  }

  .sidebar_form .select_month .dropdownvisible_demo2,
  .sidebar_form .select_day .dropdownvisible_demo2,
  .sidebar_form .select_year .dropdownvisible_demo2 {
    max-width: 196px;
  }

  .sidebar_title {
    margin-bottom: 30px;
  }

  .sidebar_list {
    margin-bottom: 20px;
  }

  .sidebarBe_banner .sidebarBe_banner_ins {
    height: 450px;
  }

  .maincontentBe {
    flex-basis: calc(100% - 260px);
  }

  .maincontentBe .front_year {
    font-size: 12px;
  }

  .sidebarBe_fixed.active {
    width: 240px;
  }

  .containerBe_forfixed .maincontentBe {
    margin-left: 18px;
  }

  .maincontent {
    padding: 0px 0 20px;
  }

  .tabs__caption li {
    padding: 5px 0;
    margin-right: 30px;
    font-size: 13px;
  }

  .tabs__content {
    padding-top: 30px;
  }

  .loadimg-view {
    margin-right: 20px;
  }

  .loadimg-view__detail {
    flex-basis: 160px;
    margin-right: 20px;
  }

  .loadimg-view__title {
    font-size: 13px;
  }

  .loadimg-view__text {
    font-size: 12px;
  }

  .labeltitle {
    font-size: 12px;
  }

  .loadimg {
    margin-bottom: 40px;
  }

  .tabform__item {
    flex-basis: 49%;
    margin-bottom: 20px;
  }

  .inputkab {
    height: 40px;
    line-height: 40px;
    padding: 5px 10px;
    font-size: 12px;
  }

  .selectday,
  .selectmonth {
    flex-basis: 26%;
  }

  textarea.inputkab {
    height: 150px;
    margin-bottom: 30px;
  }

  .addphotoplus {
    margin-left: 10px;
  }

  .selectsocial {
    flex-basis: 24%;
  }

  .pastelink {
    flex-basis: 57%;
  }

  .tabform__btnn {
    flex-basis: 17%;
  }

  .tabform__btnn .grey_link {
    font-size: 9px;
    padding: 13px 5px;
  }

  .socialline__link {
    flex-basis: 100%;
    order: 3;
    margin-top: 15px;
  }

  .socialline__icon {
    flex-basis: 16%;
  }

  .socialline__active {
    flex-basis: 20%;
  }

  .socialline__checkbox {
    flex-basis: 17%;
  }

  .socialline__delete {
    flex-basis: 7%;
    text-align: right;
    padding-right: 5px;

    .tabform__social {
      margin-bottom: 30px;
    }

    .cardcontent {
      flex-basis: 100%;
      max-width: 100% !important;
    }

    .volume__img {
      max-width: 30px;
    }

    .volume {
      width: 100%;
    }

    .certicicatehome {
      padding: 100px 0;
    }
    .certicicatehome__inside,
    .certicicatehome .back {
      padding: 40px 20px !important;
    }
    .certifcard__name {
      font-size: 45px;
    }
    .yellowbg {
      margin-bottom: -30px;
    }
  }
  @media screen and (max-width: 760px) {
    .search_text {
      font-size: 16px;
      margin: 20px 0;
    }

    .slick-slider {
      text-align: right;
    }

    .item_slider_text {
      font-size: 16px;
    }

    .item_slider_number {
      font-size: 55px;
    }

    .cards_row.active {
      height: 775px;
    }

    .show_more_cards {
      height: 60px;
      padding-left: 2px;
      width: 60px;
    }
    .show_more_cards img {
      max-width: 37px;
    }

    .line_head .memor_title {
      font-size: 12px;
    }

    .line_head .memor_price_left {
      font-size: 22px;
    }

    .line_head .per_cents {
      font-size: 16px;
    }

    .line_head {
      flex-wrap: inherit;
    }

    .line_head .per_month {
      font-size: 9px;
    }

    .memorial_line {
      margin-left: 0;
    }

    .memorial_text {
      flex-basis: 40%;
    }

    .memorial_standart,
    .memorial_premium,
    .memorial_vip {
      flex-basis: 20%;
    }

    .grey_link {
      padding: 11px;
      font-size: 8px;
    }

    .line_head .memor_price {
      padding: 18px 2px 13px;
    }

    .memorial_text_ins {
      font-size: 20px;
      text-align: center;
      letter-spacing: 1px;
      line-height: 1.2;
    }

    .breadcrumbbe ul li {
      padding: 0 15px;
    }

    .yellow_title {
      margin-bottom: 5px;
    }

    h2 {
      font-size: 30px;
    }

    p strong {
      font-size: 16px;
      line-height: 1.38;
    }

    p {
      margin-bottom: 15px;
      line-height: 1.4;
    }

    .sidebar_title {
      margin-bottom: 0;
    }

    .cardgift__title,
    .cardgift__bold {
      font-size: 18px;
    }

    .photoslider__js {
      width: 100px;
    }

    .cardgift {
      height: 350px;
      padding: 15px;
    }

    .cardtextdet__inside {
      height: 193px;
      font-size: 13px;
    }

    .cardtextdet__fouttitle {
      font-size: 10px;
    }

    .photoslider,
    .cardcontent__four_border {
      margin-bottom: 15px;
    }

    .cardcontentevents {
      padding: 15px;
    }

    .cardcontentevents__time:before {
      display: none;
    }

    .cardcontentevents__time,
    .cardcontentevents__remindme {
      flex-basis: 100px;
    }

    .cardcontentevents__title {
      padding-left: 5px;
      padding-right: 5px;
      border-right: 0;
      flex-basis: calc(100% - 190px);
      font-size: 12px;
    }

    .photoslider__js {
      width: 425px;
    }

    .tributemessage {
      margin-left: 0;
      margin-right: 0;
    }

    .tribute {
      padding: 20px 20px 30px;
    }

    .tributemessage__detail {
      font-size: 11px;
    }

    .viewalltribute {
      font-size: 11px;
      letter-spacing: 0;
    }

    .tribute .tributeinp__submit .grey_link {
      font-size: 10px;
    }

    .tribute__input .tributeinp {
      padding: 5px 10px;
      font-size: 11px;
    }

    .tributemessage__detail {
      margin-top: 0;
    }
    .certifcard__name {
      font-size: 27px;
      top: 47%;
    }
  }
  @media screen and (max-width: 460px) {
    .memorial_can_be {
      overflow: scroll;
      width: 100%;
    }

    .slick-slider {
      margin-top: 20px;
    }

    .item_slider_number {
      font-size: 40px;
    }

    .item_slider_text {
      font-size: 14px;
    }

    .cards_row.active {
      height: 1540px;
    }

    .memorial_standart,
    .memorial_premium,
    .memorial_vip {
      flex-basis: 33.3%;
      min-height: 45px;
    }

    .memorial_line {
      justify-content: center;
    }

    .memorial_text {
      flex-basis: 100%;
      text-align: center;
    }

    .memorial_text {
      padding-right: 10px;
    }

    .memorial_text_ins {
      text-align: center;
      width: 100%;
    }

    .memorial_text_ul {
      width: 100%;
      justify-content: center;
    }
    .memorial_text_ul li {
      margin: 0 5px 5px;
    }

    .line_head {
      flex-wrap: wrap;
    }

    .memorial_can_be {
      overflow: inherit;
    }

    .line_head .memor_title {
      font-size: 11px;
    }

    .memorial_line {
      border-bottom: 1px solid #5165686b;
    }

    .memorial_can_be {
      padding-bottom: 20px;
    }

    .memorial_shadow {
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
    }

    .line_a .memorial_text {
      display: none;
    }

    h2 {
      font-size: 21px;
    }

    p strong {
      font-size: 15px;
    }

    .tabs__caption li {
      margin-right: 20px;
      font-size: 12px;
      border-bottom: 2px solid transparent;
      letter-spacing: 1px;
    }

    .socialline__link {
      font-size: 11px;
    }

    .loadimg-view {
      flex-basis: 100px;
      height: 75px;
      margin-right: 10px;
    }

    .loadimg-view__detail {
      flex-basis: 95px;
      margin-right: 10px;
    }

    .loadimg-view__title {
      font-size: 11px;
    }

    .loadimg-view__text {
      font-size: 9px;
    }

    .loadimg {
      margin-bottom: 25px;
    }

    .tabform__item {
      flex-basis: 100%;
      margin-bottom: 10px;
    }

    .addphotorow .loadimg {
      max-width: 100px;
      margin-right: 10px;
    }

    .addphotorow .loadimg-view__text {
      font-size: 9px;
    }

    .addphotorow .loadimg-view {
      width: 80px;
      height: 60px;
    }

    .addphotoplus .lightbtn {
      width: 35px;
      height: 35px;
      margin-top: 10px;
    }

    .addphotoplus {
      margin-left: 0;
    }

    .pastelink {
      flex-basis: 100%;
      order: 3;
      margin-top: 10px;
    }

    .selectsocial {
      flex-basis: 46%;
    }

    .tabform__btnn {
      flex-basis: 35%;
      text-align: right;
    }

    .socialline__icon {
      flex-basis: 23%;
    }

    .socialline__delete {
      flex-basis: 8%;
    }

    .socialline__checkbox {
      flex-basis: 20%;
    }

    .socialline__active {
      flex-basis: 26%;
    }
  }

  @media screen and (max-width: 410px) {
    .cards_row.active {
      height: 1385px;
    }
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px) {
  .containerBe {
    width: 85%;
    margin: auto !important;
  }
}

@media screen and (min-width: 3000px) {
  .containerBe {
    max-width: 2100px !important;
  }
}

@media screen and (min-width: 2700px) {
  .open_menu li a {
    font-size: 15px;
  }
}

@media (min-width: 2300px) {
  .first_screen_title {
    margin-top: 309px;
  }
}
@media (min-width: 2700px) {
  .first_slider {
    margin-top: 200px;
  }
}
@media (min-width: 3100px) {
  .first_screen_title {
    margin-bottom: 50px;
    margin-top: 380px;
  }

  .first_screen {
    padding-bottom: 500px;
  }
}

@media screen and (max-width: 1600px) {
  .sidebarBe_fixed.active {
    height: 60%;
  }

  .footer_follow {
    padding-left: 15px;
    flex-basis: 11%;
  }

  .cards_ul li {
    margin: 0 8px;
  }

  .item_slider_text {
    font-size: 20px;
  }
}

@media screen and (max-width: 1120px) {
  .containerBe {
    max-width: 90%;
    margin: auto;
  }
}

@media screen and (min-width: 1000px) {
  .page_title {
    display: flex;
    justify-content: center;
    font-size: 34px;
  }

  .blue_title {
    font-size: 34px;
    letter-spacing: 8px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .containerBe {
    max-width: 90%;
  }
}

@media screen and (min-width: 981px) and (max-width: 1120px) {
  .page_title {
    text-align: center;
    font-size: 35px;
    margin-top: 60px;
  }

  .blue_title {
    font-size: 34px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 761px) and (max-width: 980px) {
  .page_title {
    text-align: center;
    font-size: 35px;
    margin-top: 60px;
  }

  .blue_title {
    font-size: 32px;
    margin-bottom: 35px;
    letter-spacing: 5px;
  }
}

@media screen and (min-width: 461px) and (max-width: 760px) {
  .page_title {
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .blue_title {
    font-weight: 500;
    font-size: 27px;
    margin-bottom: 25px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 460px) {
  .page_title {
    text-align: center;
    font-size: 15px;
    margin-top: 45px;
    margin-bottom: 10px;
  }

  .blue_title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 1100px) {
  .containerBe {
    width: 90%;
  }
}

//============================================================================//

html,
body {
  padding: 0;
  margin: 0;
  font-family: $regular-font;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: $regular-font;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: $title-font;
  color: $black-color;
}

h2 {
  font-size: 55px;
}

h3 {
  font-size: 55px;
}
