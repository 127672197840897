.errorModal {
    z-index: 9999999;
    user-select: none;
    position: fixed;
    background-color: #ffd68c;
    border-radius: 30px;
    padding: 5px;
    top: 50%;
    left: 50%;
    max-width: 500px;
    transform: translate(-50%, -50%);

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 20px;
        border: 1px solid white;
        border-radius: 25px;
    }

    &-img {
        display: flex;
        justify-content: center;
        margin: 15px;
    }

    &-text {
        display: flex;
        justify-content: center;
        margin: 15px;
        color: white;
        font-weight: 900;
        font-size: 20px;
        text-align: center;
        line-height: 1.3;
        word-break: break-word;
        overflow-y: auto;
        min-height: 45px;
        max-height: 100px;
        text-align: center;

        &::-webkit-scrollbar {
            width: 3.33px;
        
            &-thumb {
                background-color: white;
                outline: 1px solid white;
                border-radius: 25px;
                box-shadow: 0 0 3.33px 1.11px rgba(127, 170, 176, 0.333);
            }
      
            &-track {
                -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.111);
            }
        }
    }

    &-close {
        position: absolute;
        right: 12px;
        top: 12px;
        height: 32px;
        width: 32px;
        cursor: pointer;
    }
}

@media screen and ( max-width: 768px ) {
    .errorModal {
        width: 100%;
        max-width: 500px;
    }
}

@media screen and ( max-width: 576px ) {
    .errorModal {
        width: 100%;
        max-width: 300px;
    }
}