/*-------------------------footer------------------------*/
.footer {
    margin-top: 67px;
    background-color: #f6f6f6;
    padding-bottom: 35px;
    padding-top: 40px; 
  }
  
  .footer .footer_row {
    max-width: 1230px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px; 
  }
  
  .footer .footer_bottom {
    max-width: 1230px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 46px;
    align-items: center;
    position: relative; 
  }
  
  .footer .footer_bottom:before {
    content: "";
    display: block;
    background-color: #c5d3d9;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0; 
  }
  
  .footer .footer_logo {
    position: relative;
    padding-right: 15px;
    background-color: #f6f6f6; 
  }
  
  .footer .footer_copy {
    position: relative;
    padding-left: 15px;
    background-color: #f6f6f6;
    color: #6c7a80;
    font-size: 14px; 
  }
  
  .footer_top {
    max-width: 1570px;
    width: 100%; 
  }
  
  .footer_cards {
    width: 33.3%;
    align-items: center;
    justify-content: end;
    padding-right: 10px;
    border-right: 1px solid #c5d3d9; 
  }
  
  .funeral {
    padding-right: 10px;
    padding-left: 10px;
    border-right: 1px solid #c5d3d9; 
  }
  
  .footer_address {
    width: 33.3%;
    padding-left: 35px;
    padding-right: 10px;
    border-right: 1px solid #c5d3d9; 
  }
  
  .funeral {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; 
    width: 33.3%;
  }
  
  .funeral li {
    flex-basis: 50%;
    text-align: center;
    color: #6c7a80;
    font-size: 13px;
    font-weight: 500; 
  }
  
  .funeral li:nth-child(2n) {
    border-left: 1px solid #c5d3d9;
   }
  
  .funeral li a {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    display: block;
    color: #6c7a80;
    font-size: 13px;
    font-weight: 400; 
  }
  
  .footer_tit {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #6c7a80;
    letter-spacing: 1px;
    font-family: 'Roboto', sans-serif; 
  }
  
  .adress_text {
    font-size: 13px;
    color: #6c7a80;
    letter-spacing: 1px;
    font-family: 'Roboto', sans-serif; 
  }

ol, ul, li {
    // list-style-type: none;
    font-family: 'Roboto', sans-serif;
}

.firstBlockContainer-item li {
    font-size: 13px;
}

.firstBlockContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &-item {
        display: flex;
        justify-content: center;
        margin-left: 5px;
        font-family: 'Roboto', sans-serif;

        &-link {
            color: #6c7a80;
        }

        &:first-of-type {
            width: 100%;
            font-size: 13px;
            margin-bottom: 5px;
            justify-content: center;
        }
    }

    .firstBlockTitle {
        font-weight: 700;
    }
}

.leftBorder {
    border-left: 1px solid #c5d3d9 ;
}

@media screen and ( min-width: 3000px ) {
    .footer_tit {
        font-size: 21px;
    }

    .funeral li a {
        font-size: 21px;
    }
    
      .adress_text {
        font-size: 21px;
    }
    
      .firstBlockContainer-item-link {
        font-size: 21px;
    }
    
      .firstBlockContainer-item a img {
        height: 30px;
        width: 30px;
    }
    
      .footer .footer_copy {
        font-size: 21px;
    }
}

@media screen and ( min-width: 1301px ) and ( max-width: 1600px ) {
    .funeral {
        flex-basis: 35%; 
    }
    
    .footer_address {
        flex-basis: 22%;
        padding-left: 15px; 
    }
    
      .footer_cards {
        flex-basis: 31%; 
    }

    .footer_follow {
        padding-left: 15px;
        flex-basis: 11%;
    }
    
    .footer_address {
        flex-basis: 22%;
        padding-left: 15px;
    }
}

@media screen and ( max-width: 1400px ) {
    .funeral {
        flex-basis: 34%; 
    }
    
      .funeral li {
        flex-basis: 100%;
        border: 0 !important; 
    }
}

@media screen and ( max-width: 1120px ) {
    .footer {
        margin-top: 30px;
        padding-bottom: 15px;
        padding-top: 25px;
    }
    .footer .footer_bottom {
        margin-top: 20px;
    }
    .funeral,
    .footer_follow {
        flex-basis: 50%;
        border: 0;
        margin: 10px 0;
        text-align: center;
    }
    .footer_cards,
    .footer_address {
        padding-left: 0;
        flex-basis: 50%;
        margin: 10px 0;
    }
    .follow_li {
        justify-content: center;
    }
    .follow_li li {
        margin-right: 8px;
        margin-left: 8px;
    }
}

@media screen and ( min-width: 981px ) and ( max-width: 1300px ) {
    .footer_cards {
        flex-basis: 25%; 
    }
    
    .footer_follow {
        flex-basis: 16%; 
    }
    
    .funeral {
        flex-basis: 36%; 
    }
}

@media screen and ( min-width: 761px ) and ( max-width: 980px ) {
    .footer {
        margin-top: 30px;
        padding-bottom: 15px;
        padding-top: 25px; }
    
      .footer .footer_bottom {
        margin-top: 20px; }
    
      .funeral,
      .footer_follow {
        flex-basis: 50%;
        border: 0;
        margin: 10px 0;
        text-align: center; }
    
      .footer_cards,
      .footer_address {
        padding-left: 0;
        flex-basis: 50%;
        margin: 10px 0; }
    
      .follow_li {
        justify-content: center; }
    
      .follow_li li {
        margin-right: 8px;
        margin-left: 8px; }

    .footer .footer_copy {
        font-size: 12px;
        text-align: right;
        span {
            display: block;
        }
    }
}

@media screen and ( min-width: 461px ) and ( max-width: 760px ) {
    .footer .footer_copy {
        font-size: 12px;
        text-align: right; 
    }
    
      .footer .footer_copy span {
        display: block; 
    }
    
      .adress_text {
        font-size: 12px; 
    }
}

@media screen and ( max-width: 460px ) {
    .funeral,
  .footer_follow,
  .footer_cards,
  .footer_address {
    flex-basis: 90%;
    padding-left: 0;
    padding-bottom: 10px;
    padding-right: 0;
    border: 0;
    border-bottom: 1px solid #c5d3d9;
    margin: 10px auto;
    text-align: center; }

  .footer_cards_title {
    padding: 0; }

  .footer_cards {
    display: flex;
    flex-direction: column;
    text-align: center; 
  }
}