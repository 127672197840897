.headerAvatar {
  width: 46px;
  height: 46px;
}

.menu {
  position: relative;
  list-style: none;
  width: 46px;
  height: 46px;

  .menuList {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 260px;
    right: 0px;
    transform: translateY(10px);

    padding: 16px 16px;
    background-color: #fff;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    list-style: none;

    animation: fate 0.5s ease forwards;

    .menuLink {
      a, p {
        font-family: "Gilroy", sans-serif;
        width: 100%;
      }
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      padding: 14px 8px;
      border-radius: 10px;
      background-color: #fff;
      width: 100%;

      transition: background-color 0.3s;

      &:hover {
        background-color: #e4f5f6;
      }
    }
  }
}

@keyframes fate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
