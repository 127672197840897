@import "../../../../../styles/theme.scss";

.footerLogoContainer {
  display: flex;
  align-items: center;
  gap: 7px;

  .image {
    width: 156px;
    height: 33px;

    @include tablet {
      width: 156px;
      height: 33px;
    }
  }

  .text {
    font-family: $title-font;
    font-size: 23px;
    color: $blue-color;

    @include mobile {
      padding-top: 14px;
    }
  }
}
