@import "../../../../../styles/theme.scss";

.socialContainer {
  display: flex;
  margin-top: 12px;
  margin-right: 140px;
  gap: 12px;

  @include tablet {
    margin: 0;
    margin-top: 12px;
  }

  img {
    cursor: pointer;
    max-width: unset;
  }
}
