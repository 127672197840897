@font-face {
  font-family: "Cormorant Bold";
  src: url("../assets/fonts/Cormorant-Bold.ttf");
}

@font-face {
  font-family: "Gilroy Regular";
  src: url("../assets/fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "Gilroy Bold";
  src: url("../assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "Gilroy Medium";
  src: url("../assets/fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy SemiBold";
  src: url("../assets/fonts/Gilroy-SemiBold.ttf");
}

// ============ VARIABLES ===================

$title-font: "Cormorant Bold";
$regular-font: "Gilroy Regular";

$black-color: #333333;
$white-color: #fcfbfc;
$grey-color: #757575;
$blue-color: #35cfd4;
$deep-blue-color: #296b73;
$deep-green: #0f282b;

$screen-sm-max: 750px;
$screen-small-laptop: 1350px;
$screen-tablet: 1200px;

//============ COMMON STYLES ==============

%p1 {
  font-family: $regular-font;
  font-size: 16px;
  font-weight: normal;
}

%h1 {
  font-family: $title-font;
  font-size: 60px;
}

%h2 {
  font-family: $title-font;
  font-size: 54px;
}

%h3 {
  font-family: $title-font;
  font-size: 34px;
}

%light_shadow {
  -webkit-box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.48);
}

//==========MIXINES==============

@mixin mobile {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin small-laptop {
  @media (max-width: #{$screen-small-laptop}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$screen-tablet}) {
    @content;
  }
}
